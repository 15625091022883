<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
        <i class="icon-user mr-1"></i>{{ formLabel }} 
        <b-form-checkbox 
          v-model="form.active" 
          id="formfield-user-active" 
          class="float-right"
          :value="true"
          :unchecked-value="false"
        >Active</b-form-checkbox>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show" autocomplete="off">
        <b-form-row>
          <b-col cols="12" md="4">
            <b-form-group
              id="formgroup-user-fullname"
              label="First &amp; Last Name:"
              label-for="formfield-user-fullname"
            >
              <b-form-input id="formfield-user-fullname" autocomplete="off"
                            type="text"
                            v-model="form.fullName"
                            required
                            placeholder="John Smith">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group id="formgroup-user-roles"
                          label="Role:"
                          label-for="formfield-user-roles">
              <b-form-select id="formfield-user-roles"
                            :options="userRoleOptions"
                            required
                            v-model="form.roles">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group id="formgroup-createBusiness" v-if="!userId && form.roles.includes('ROLE_DIRECTOR')" class="mt-4">
              <b-form-checkbox v-model="createBusiness" id="formfield-createBusiness" class="mt-2">New franchise</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        
        <b-form-row>
          <b-col cols="12" md="4">
            <b-form-group id="formgroup-user-email"
                          label="Email"
                          label-for="formfield-user-email">
              <b-form-input 
                id="formfield-user-email"
                type="email"
                v-model="form.email"
                placeholder="jsmith@example.com"
                required
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group id="formgroup-user-username"
                          label="Username:"
                          label-for="formfield-user-username">
              <b-form-input 
                id="formfield-user-username"
                type="text"
                v-model="form.username"
                required
                placeholder="jsmith"
                v-lowercasenospace
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group id="formgroup-user-password"
                          label="Password"
                          label-for="formfield-user-password"
                            description="Only if new or changing">
              <b-form-input id="formfield-user-password"
                            type="text"
                            v-model="form.plainPassword"
                            :placeholder="passwordPlaceholder">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        
        <b-form-row>
          <b-col cols="12" md="3">
            <b-form-group id="formgroup-user-phone"
                          label="Phone:"
                          label-for="formfield-user-phone">
              <masked-input 
                id="formfield-user-phone" 
                class="form-control"
                v-model="form.phone"
                :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                placeholderChar="#"
              ></masked-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="formgroup-user-address1"
                          label="Address:"
                          label-for="formfield-user-address1">
              <b-form-input id="formfield-user-address1"
                            type="text"
                            v-model="form.address1"
                            placeholder="123 example st">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="formgroup-user-address2"
                          label="Apt:"
                          label-for="formfield-user-address2">
              <b-form-input id="formfield-user-address2"
                            type="text"
                            maxlength="10"
                            v-model="form.address2">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        
        <b-form-row>
          <b-col md="4" cols="12">
            <b-form-group id="formgroup-user-city"
                          label="City:"
                          label-for="formfield-user-city">
              <b-form-input id="formfield-user-city"
                            type="text"
                            v-model="form.city">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4" cols="8">
            <b-form-group id="formgroup-user-state"
                          label="State:"
                          label-for="formfield-user-state">
              <b-form-select id="formfield-user-state"
                            :options="states"
                            v-model="form.state">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4" cols="4">
            <b-form-group id="formgroup-user-zipCode"
              label="Zip:"
              label-for="formfield-user-zipCode"
            >
              <b-form-input id="formfield-user-zipCode"
                type="number"
                v-model="form.zipCode"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row v-if="showSportsOptions">
          <b-col>
            <p>
              Approved Sports To Coach:
            </p>
            <b-form-checkbox-group
              v-model="form.sports"
              :options="sports"
              class="mb-3"
              value-field="@id"
              text-field="label"
            ></b-form-checkbox-group>
          </b-col>
        </b-form-row>
        
        <div class="clearfix">
          <b-button type="reset" variant="danger">Reset</b-button>
          <b-button type="submit" variant="success" class="float-right">Submit</b-button>
        </div>
      </b-form>
      </b-card-body>
    </b-card>
    <!-- status -->
    <b-modal ref="userFormStatusModal" @ok="doCreateBusiness()" ok-only hide-header>
      <b-container fluid>
       {{ formStatus }}
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MaskedInput from 'vue-text-mask'
import { constants } from '@/shared/constants'
const DEFAULT_ACTIVE = false
export default {
  name: 'UserForm',
  data () {
    return {
      form: {
        email: '',
        fullName: '',
        plainPassword: '',
        roles: [],
        active: DEFAULT_ACTIVE,
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        sports: []
      },
      show: true,
      userId: null,
      formStatus: null,
      createBusiness: false,
      showSportsOptions: false
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'userList'
    ]),
    sports () {
      let s = this.$store.getters.sports
      return s.filter((e) => {
        return (!constants.LOOKUP_EXCLUDE.includes(e.label))
      })
    },
    defaultUsername () {
      return this.form.email.split('@')[0]
    },
    states () {
      return this.$store.getters.states
    },
    passwordPlaceholder () {
      return (!this.userId) ? '3ggs4ndp4nc4k3s!' : '' 
    },
    formLabel () {
      return (!this.userId) ? 'User Form' : this.form.fullName
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'postUser',
      'putUser',
      'resetUsers',
      'getSports'
    ]),
    loadUser () {
      let params = {id: this.userId}
      if (!this.isAdmin) {
        params.businesses = this.business.id
      }
      this.getUsers(params).then(() => {
        if (!this.userList.length) {
          this.$router.push({name: 'users'})
          this.showError({ message: "the user could not be found" })
        }
        let user = this.userList[0]
        this.form.active = user.active
        this.form.fullName = user.fullName
        this.form.username = user.username
        this.form.email = user.email
        this.form.roles = user.roles[0] // convert to string
        this.form.phone = user.phone
        this.form.address1 = user.address1
        this.form.address2 = user.address2
        this.form.city = user.city
        this.form.state = user.state
        this.form.zipCode = user.zipCode
        this.form.sports = user.sports
      })
    },
    async onSubmit () {
      let data = { ...this.form }
      data.roles = [data.roles] // convert back to array
      try {
        await this.$recaptchaLoaded()
        data.captcha = await this.$recaptcha('admin_user_form')
        if (this.userId) {
          if (data.plainPassword === '') {
            delete data.plainPassword
          }
          let obj = { 
              uid: this.userId,
              data: data
          }
          await this.putUser(obj)
          this.formStatus = 'the user has been updated'
          this.$refs.userFormStatusModal.show()
        } else {
          if (typeof this.business.id !== 'undefined' && this.business.id !== null) {
            data.businesses = []
            data.businesses.push(this.business['@id'])
          }
          let user = await this.postUser(data)
          this.userId = user.id
          this.formStatus = 'the user has been added'
          this.$refs.userFormStatusModal.show()
          if (data.roles.includes('ROLE_MEMBER')) {
            this.$router.push({ name: 'participantForm', params: { uid: user.id } })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    resetUserData () {
      /* Reset our form values */
      this.userId = null
      this.form.email = ''
      this.form.fullName = ''
      this.form.username = ''
      this.form.plainPassword = ''
      this.form.roles = 'ROLE_MEMBER'
      this.form.phone = ''
      this.form.address1 = ''
      this.form.address2 = ''
      this.form.city = ''
      this.form.state = ''
      this.form.zipCode = ''
      this.form.active = DEFAULT_ACTIVE
    },
    onReset (evt) {
      evt.preventDefault()
      this.resetUserData()
      /* Trick to reset/clear native browser form validation state */
      this.show = false
      this.$nextTick(() => { this.show = true })
    },
    doCreateBusiness () {
      if (this.createBusiness === true) {
        this.$router.push('/business/form?uid=' + this.userId)
      }
    },
    passwordGenerate () {
      let size = 8,
      charactersArray = 'a-z,A-Z,0-9,#'.split(','),
      characterSet = '',
      password = ''

      if( charactersArray.indexOf('a-z') >= 0) {
        characterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if( charactersArray.indexOf('A-Z') >= 0) {
        characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if( charactersArray.indexOf('0-9') >= 0) {
        characterSet += '0123456789'
      }
      if( charactersArray.indexOf('#') >= 0) {
        characterSet += '![]{}()%&*$#^<>~@|'
      }
      
      for(let i=0; i < size; i++) {
        password += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
      }
      this.form.plainPassword = password
    }
  },
  directives: {
    lowercasenospace: {
      bind(el, _, vnode) {
        el.addEventListener('keyup', e => {
          e.target.value = e.target.value.toLowerCase().replace(/\s/, '')
          vnode.componentInstance.$emit('input', e.target.value.toLowerCase().replace(/\s/, ''))
        })
      }
    }
  },
  watch: {
    'form.roles' (val) {
      if (!this.userId && val === 'ROLE_DIRECTOR') {
        this.createBusiness = true
      } else {
        this.createBusiness = false
      }

      if (val === 'ROLE_COACH' || val === 'ROLE_COACH_JR') {
        this.showSportsOptions = true
      } else {
        this.showSportsOptions = false
      }
    },
    'form.email' () {
      if (this.userId) return
      this.form.username = this.defaultUsername
    },
    userId (val) {
      this.loadUser()
    }
  },
  mounted() {
    this.getSports()
    if (this.$route.query.uid) {
      this.userId = this.$route.query.uid
    } else if (this.$route.params.uid) {
      this.userId = this.$route.params.uid
    }
    if (this.$route.query.role) {
      this.form.roles = this.$route.query.role
    }
    if (this.isDirector) {
      this.form.roles = 'ROLE_MEMBER'
    }
    if (!this.userId) {
      this.passwordGenerate()
      this.form.active = true
    }
  },
  destroyed() {
    this.resetUsers()
  },
  components: { MaskedInput }
}
</script>
